<template>
  <page-header-wrapper>
    <div class="a-card">
      <AppVersionSearch @resetData="resetSearchForm" @handleAdd="handleAdd"/>
      <div class="table-operator">
        <a-button type="dashed" @click="tableOption">{{ (optionAlertShow && '关闭') || '开启' }} alert</a-button>
        <!-- <a-dropdown v-if="selectedRowKeys.length > 0">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            Hover me
            <a-icon type="down"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" @click="handleDelete()">
              <a-icon type="delete"/>
              删除
            </a-menu-item>
            <a-menu-item class="hide" key="2">
              <a-icon type="down"/>
              批量操作
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :columns="columns"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :pagination="{ pageSize: 10, pageNo: 1 }"
        :scroll="{ x: true, }"
        :row-key="(record) => record.appVersionId"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="marketplaceCode" slot-scope="text, record">
          {{ record.country }}- {{ record.marketplaceCode }}-
          {{ record.marketplaceName }}
        </div>
        <div slot="siteCode" slot-scope="text, record">
          {{ record.siteCode }}-
          {{ record.siteName }}
        </div>
        <div slot="appCode" slot-scope="text, record">
          {{ record.appCode }}-
          {{ record.appName }}
        </div>
        <div slot="isForceUpdate" slot-scope="text">
          <span>{{ text | getIsForceUpdate }}</span>
        </div>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item vv1-if="$auth('table.approval')" v-if="record.approvalStatus !== '已通过'">
                <a @click="handleApproval(record)">{{ $t('button.approval') }}</a>
              </a-menu-item>

              <a-menu-item v-if="record.status === 1">
                <a @click="handleDisable(record)">{{ $t('button.disable') }}</a>
              </a-menu-item>
              <a-menu-item v-if="record.status === 0">
                <a @click="handleEnable(record)">{{ $t('button.enable') }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <div slot="creatorInfo" slot-scope="text, record">
          {{ record.creatorName }}<br />
          {{ formatDateTime(record.createTime) }}
        </div>
        <div slot="updaterInfo" slot-scope="text, record">
          {{ record.updaterName }}<br />
          {{ formatDateTime(record.updateTime) }}
        </div>
      </s-table>
    </div>

    <AppVersionEdit ref="appVersionEdit" />
    <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk" />
    <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk" />
  </page-header-wrapper></template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  approvalAppVersion,
  deleteAppVersion,
  disableAppVersion,
  enableAppVersion,
  getAppVersionData,
  saveAppVersionData,
  searchAppVersion
} from '@/api/system/appVersion'
import { STable } from '@/components'

import AppVersionSearch from '@/views/system/AppVersionSearch'
import AppVersionEdit from '@/views/system/components/AppVersionEdit'
import CommentEditor from '@/components/comment/CommentEditor'
import ApprovalEditor from '@/components/approval/ApprovalEditor'

export default {
  name: 'AppVersionList',
  components: {
    CommentEditor,
    STable,
    AppVersionSearch,
    AppVersionEdit,
    ApprovalEditor
  },
  data () {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: { siteCode: [] },
      data: [],
      status: 'all',
      columns: [
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          },
          width: 120
        },
        {
          defaultTitle: 'APP编码',
          title: this.$t('columnName.appCode'),
          dataIndex: 'appCode',
          key: 'appCode',
          align: 'left'
        },
        {
          defaultTitle: 'APP名称',
          title: this.$t('columnName.appName'),
          dataIndex: 'appName',
          key: 'appName',
          align: 'left'
        },
        {
          defaultTitle: 'APP版本',
          title: this.$t('columnName.appVersion'),
          dataIndex: 'appVersion',
          key: 'appVersion',
          align: 'left'
        },
        {
          defaultTitle: '是否强制更新',
          title: this.$t('columnName.isForceUpdate'),
          dataIndex: 'isForceUpdate',
          key: 'isForceUpdate',
          align: 'left',
          scopedSlots: {
            customRender: 'isForceUpdate'
          },
          width: 120
        },
        {
          defaultTitle: '平台编码',
          title: this.$t('columnName.platformCode'),
          dataIndex: 'platformCode',
          key: 'platformCode',
          align: 'left'
        },
        {
          defaultTitle: '平台名称',
          title: this.$t('columnName.platformName'),
          dataIndex: 'platformName',
          key: 'platformName',
          align: 'left'
        },
        {
          defaultTitle: '平台版本',
          title: this.$t('columnName.platformVersion'),
          dataIndex: 'platformVersion',
          key: 'platformVersion',
          align: 'left'
        },
        {
          defaultTitle: '站点名称',
          title: this.$t('columnName.siteName'),
          dataIndex: 'siteName',
          key: 'siteName',
          align: 'left',
          scopedSlots: {
            customRender: 'siteCode'
          }
        },
        {
          defaultTitle: '强制更新说明',
          title: this.$t('columnName.forceUpdateDescription'),
          dataIndex: 'forceUpdateDescription',
          key: 'forceUpdateDescription',
          align: 'left',
          width: 120
        },
        {
          defaultTitle: '发布日期',
          title: this.$t('columnName.releaseDate'),
          dataIndex: 'releaseDate',
          key: 'releaseDate',
          align: 'center',
          scopedSlots: {
            customRender: 'date'
          }
        },
        {
          defaultTitle: '文件大小',
          title: this.$t('columnName.fileSize'),
          dataIndex: 'fileSize',
          key: 'fileSize',
          align: 'left'
        },
        {
          defaultTitle: '状态',
          title: this.$t('columnName.status'),
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          customRender: (text) => this.getCode('enableStatus', text)
        },
        {
          defaultTitle: '创建人',
          title: this.$t('columnName.creatorName'),
          dataIndex: 'creatorName',
          key: 'creatorName',
          align: 'left',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'creatorInfo'
          }
        },
        {
          defaultTitle: '更新人',
          title: this.$t('columnName.updaterName'),
          dataIndex: 'updaterName',
          key: 'updaterName',
          align: 'left',
          ellipsis: true,
          width: 100,
          scopedSlots: {
            customRender: 'updaterInfo'
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getAppVersion(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchAppVersion(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->getAppVersionList.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleAdd () {
      this.$refs['appVersionEdit'].add()
    },

    handleEdit (record) {
      const that = this
      getAppVersionData({ appVersionId: record.appVersionId })
        .then((res) => {
          this.$refs['appVersionEdit'].edit(res)
        })
        .catch((res) => {
          that.$message.success(this.$t('get.entity.appVersion.fail'))
        })
      // 弹出编辑页面
      this.appVersionModalShow = true
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableAppVersion(record)
          .then((res) => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableAppVersion(record)
          .then((res) => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm (data) {
      this.queryParam = data
      this.$refs.table.refresh(true)
    },
    handleDelete () {
      const that = this
      const params = {
        appVersionId: this.selectedRows[0].appVersionId
      }
      deleteAppVersion(params)
        .then((res) => {
          that.$message.success(this.$t('save.entity.afterSaleService.success'))
        })
        .catch((res) => {
          that.$message.success(this.$t('save.entity.afterSaleService.fail'))
        })
      that.$refs.table.refresh(true)
    },
    handleApprovalEditOk (params) {
      const record = params.record
      const that = this
      return approvalAppVersion({
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过', /** 待审核, 已通过, 已拒绝, 审核中 */
        appVersionId: record.appVersionId
      })
        .then((res) => {
          console.log('handleApprovalEditOk', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('审批成功')
          return res
        })
        .catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('审批失败')
        })
    },
    handleApproval (record) {
      this.$refs['approvalEditor'].show(record)
    }
  },
  filters: {
    getIsForceUpdate (val) {
      if (val) {
        return '是'
      } else {
        return '否'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
