import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveMarketplaceData (parameter) {
  let url = baseUrl + '/system/marketplace/save'
  if (!parameter.marketplaceId) {
    url = baseUrl + '/system/marketplace/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchMarketplace (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listMarketplace (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getMarketplaceData (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableMarketplace (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableMarketplace (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteMarketplace (parameter) {
  return request({
    url: baseUrl + '/system/marketplace/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
