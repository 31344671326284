<template>
  <a-modal
    width="50%"
    :title="$t('page.app_version')"
    :visible="appVersionModalShow"
    @ok="handleEditOk"
    @cancel="handleEditCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <a-form
        :form="form"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        <a-form-item
          :label="$t('field.appCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select v-model="appVersionData.appCode" mode="default" @change="onAppChanged">
            <a-select-option
              v-for="(item, index) in codeList.app"
              :key="index"
              :appCode="item.appCode"
              :appName="item.appName"
              :value="item.appCode"
              style="width: 400px"
            >
              <!-- [{{ item.marketplaceCode }}-{{ item.marketplaceName }}-{{ item.siteCode }}-{{ item.siteName}}]   -->
              {{ item.appCode }}-{{ item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.appVersion')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'appVersion',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.appVersion') },
                  { max: 100, message: this.$t('field.appVersion') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.appVersion"
            v-model="appVersionData.appVersion"
            :placeholder="'请输入' + this.$t('field.appVersion')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.platformCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'platformCode',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.platformCode') },
                  { max: 100, message: this.$t('field.platformCode') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.platformCode"
            v-model="appVersionData.platformCode"
            :placeholder="'请输入' + this.$t('field.platformCode')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.platformName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'platformName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.platformName') },
                  { max: 100, message: this.$t('field.platformName') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.platformName"
            v-model="appVersionData.platformName"
            :placeholder="'请输入' + this.$t('field.platformName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.platformVersion')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'platformVersion',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.platformVersion') },
                  { max: 100, message: this.$t('field.platformVersion') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.platformVersion"
            v-model="appVersionData.platformVersion"
            :placeholder="'请输入' + this.$t('field.platformVersion')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.isForceUpdate')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select mode="default" v-model="appVersionData.isForceUpdate">
            <a-select-option
              v-for="(item, index) in codeList.isForceUpdate"
              :key="index"
              :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.forceUpdateDescription')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'forceUpdateDescription',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.forceUpdateDescription') },
                  { max: 100, message: this.$t('field.forceUpdateDescription') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.forceUpdateDescription"
            v-model="appVersionData.forceUpdateDescription"
            :placeholder="'请输入' + this.$t('field.forceUpdateDescription')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.releaseDate')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-date-picker
            v-model="appVersionData.releaseDate"
            style="width: 100%"
            valueFormat="YYYY-MM-DD"
            v-decorator="['startAt', { rules: [{ required: true, message: '请选择开始时间' }] }]"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.fileSize')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'fileSize',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.fileSize') },
                  { max: 100, message: this.$t('field.fileSize') + '长度小于等于100' },
                ],
              },
            ]"
            name="appVersionData.fileSize"
            v-model="appVersionData.fileSize"
            :placeholder="'请输入' + this.$t('field.fileSize')"
          />
        </a-form-item>
      </a-form></a-card>
  </a-modal>
</template>

<script>
import { listApp } from '@/api/system/app'
import { saveAppVersionData } from '@/api/system/appVersion'

export default {
  name: 'EditAppVersion',
  props: {},
  data () {
    return {
      form: this.$form.createForm(this),
      appVersionModalShow: false,
      appVersionData: {},
      codeList: {
        app: []
      }
    }
  },
  mounted () {
    const that = this
    listApp({}).then(res => {
      that.codeList.app = res.data
    })
  },
  methods: {
    createForm () {
      let that = this
      this.$nextTick(() => {
        const formData = {}
        Object.keys(that.form.getFieldsValue()).map(key => (formData[key] = this.appVersionData[key]))
        that.form.setFieldsValue(formData)
        console.log('初始化form', that.form)
      })
    },
    add () {
      this.appVersionData = {}
      this.createForm()
      this.appVersionModalShow = true
    },
    edit (data) {
      this.appVersionData = data
      this.createForm()
      // 弹出编辑页面
      this.appVersionModalShow = true
    },
    onAppChanged (value, option) {
      // this.appVersionData.marketplaceCode = value
      // this.appVersionData.marketplaceName = option.data.attrs.marketplaceName
      // this.appVersionData.marketplaceId = option.data.attrs.marketplaceId
      this.appVersionData.appCode = value
      this.appVersionData.siteName = option.data.attrs.siteName
      this.appVersionData.siteId = option.data.attrs.siteId
      this.appVersionData.appName = option.data.attrs.appName
      this.appVersionData.appId = option.data.attrs.appId
      this.appVersionData.country = option.data.attrs.country
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.appVersionModalShow = false
    },
    handleEditOk (record) {
      const that = this
      this.form.validateFieldsAndScroll((err, values) => {
        console.log('validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        Object.assign({ ...that.marketplaceData, ...that.form.getFieldsValue() })
        saveAppVersionData(this.appVersionData)
          .then((res) => {
            this.appVersionData = {}
            // 弹出编辑页面
            this.appVersionModalShow = false
            that.$message.success(this.$t('save.entity.appVersion.success'))
            that.$parent.$parent.$refs.table.refresh(true)
          })
          .catch((res) => {
            that.$message.success(this.$t('save.entity.appVersion.fail'))
          })
      })
    }
  }
}
</script>
<style scoped>
.ant-select{
  width:100%;
}
</style>
