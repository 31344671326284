import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveApp (parameter) {
  let url = baseUrl + '/system/app/save'
  if (!parameter.appId) {
    url = baseUrl + '/system/app/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchApp (parameter) {
  return request({
    url: baseUrl + '/system/app/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listApp (parameter) {
  return request({
    url: baseUrl + '/system/app/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getAppData (parameter) {
  return request({
    url: baseUrl + '/system/app/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableApp (parameter) {
  return request({
    url: baseUrl + '/system/app/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableApp (parameter) {
  return request({
    url: baseUrl + '/system/app/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteApp (parameter) {
  return request({
    url: baseUrl + '/system/app/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
