import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveSiteData (parameter) {
  let url = baseUrl + '/system/site/save'
  if (!parameter.siteId) {
    url = baseUrl + '/system/site/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSite (parameter) {
  return request({
    url: baseUrl + '/system/site/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSite (parameter) {
  return request({
    url: baseUrl + '/system/site/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getSiteData (parameter) {
  return request({
    url: baseUrl + '/system/site/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSite (parameter) {
  return request({
    url: baseUrl + '/system/site/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSite (parameter) {
  return request({
    url: baseUrl + '/system/site/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSite (parameter) {
  return request({
    url: baseUrl + '/system/site/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
