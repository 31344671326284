import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveAppVersionData (parameter) {
  let url = baseUrl + '/system/appVersion/save'
  if (!parameter.appVersionId) {
    url = baseUrl + '/system/appVersion/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function getAppVersionData (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function approvalAppVersion (parameter) {
  return request({
    url: baseUrl + '/system/appVersion/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
